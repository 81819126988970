<template>
  <div>
    <h4>Loading...</h4>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Hello there");
    this.$nextTick(() => {
      this.AuthService.setAccessToken();
      this.AuthService.setIdToken();
      this.AuthService.storePermissions();

      this.$router.push("/");
    });
  },
};
</script>
